import { MiniWishlist } from './MiniWishlist.ts';
import { ToggleBox } from '../toggles/toggleBox.js';

import '../../scss/miniWishlist/main.scss';

let initialized = false;

window.o_global.eventLoader.onAllScriptsExecuted(10, function () {
    if (!initialized) {
        this.initialized = true;
        const toggles = new ToggleBox(document.querySelector('.wl_toggleInfo'));
        const element = document.querySelector('.wl_mini__link');
        new MiniWishlist(element, toggles).init();
    }
});
